//
// Badge
// --------------------------------------------------


// Fix secondary and light badges colors

.badge.bg-secondary,
.badge.bg-light {
  --#{$prefix}badge-color: var(--#{$prefix}gray-800);
}
.badge.bg-secondary {
  background-color: var(--#{$prefix}secondary) !important;
}

// Badge link

a.badge {
  text-decoration: none;
}
