.event-progress canvas {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 15px -5px #002435;
}

 .dark-mode {
  .event-progress canvas {
    background-color: var(--ar-card-bg)!important;
  }
}
