.invalid-feedback {
  display: block;
}

//https://stackoverflow.com/a/33132624/1785313
//Alternatives:
//border-bottom border-secondary border-opacity-10
//d-flex flex-column
.no-collapse {
  padding: 0.05px;
}

.no-collapse-border {
  border: 0.05px solid transparent;
}

//Boostrap 5.3
.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s;

  &:hover {
    -webkit-filter: grayscale(0);
    filter: none;
    transition: 0.3s;
  }
}

.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
