ng-select {
  &.form-control,
  &.form-select {
    padding-right: 0 !important;

    .ng-select-container {
      height: auto !important;
      min-height: 0;
      border: none;
      border-color: inherit;
      box-shadow: none !important;
      background-color: transparent;

      .ng-value-container {
        padding-left: 0;

        .ng-input {
          top: auto !important;
          padding-left: 0 !important;
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        background-color: transparent;
      }
    }
  }
}
