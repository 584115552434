/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 * Use https://sass-guidelin.es/#the-7-1-pattern
 */


// 1. Abstracts
@import "abstracts/user-variables";
@import "abstracts/variables";
@import "abstracts/utilities";

// 2. Vendors
//Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

@import "../node_modules/@swimlane/ngx-datatable/index";
// @import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons";
@import 'ngx-toastr/toastr-bs5-alert';
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/autoplay";
@import "swiper/scss/effect-fade";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "aos/src/sass/aos";

// 3. Base
@import "base/reboot";
@import "base/typography";

// 4. Layout-related sections
// @import 'layout/navigation';
// @import 'layout/grid';
// @import 'layout/header';
// @import 'layout/footer';
// @import 'layout/forms';

// 5. Components
@import 'components/icons';
@import 'components/type';
@import 'components/code';
@import 'components/tables';
@import 'components/forms';
@import 'components/input-group';
@import 'components/buttons';
@import 'components/social-buttons';
@import 'components/dropdown';
@import 'components/nav';
@import 'components/navbar';
@import 'components/card';
@import 'components/accordion';
@import 'components/breadcrumb';
@import 'components/pagination';
@import 'components/badge';
@import 'components/alert';
@import 'components/list-group';
@import 'components/toasts';
@import 'components/modal';
@import 'components/popover';
@import 'components/offcanvas';
@import 'components/custom-scrollbar';
@import 'components/masonry-grid';
@import 'components/carousel';
@import 'components/gallery';
@import 'components/parallax';
@import 'components/map';
@import 'components/date-picker';
@import 'components/calendar';
@import 'components/steps';
@import 'components/hotspots';
@import 'components/binded-content';
@import 'components/image-effects';
@import 'components/animations';
@import 'components/ng-select';
@import 'components/ngx-datatable';
@import 'components/ngx-gauge';

// 6. Pages
// @import 'pages/home';
// @import 'pages/contact';

// 7. Themes
@import 'themes/dark-mode';

@import 'shame';

