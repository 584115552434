//
// Typography
// --------------------------------------------------


// Headings line height

h4, .h4, .fs-4,
h5, .h5, .fs-5,
h6, .h6, .fs-6 { line-height: $line-height-sm; }


// Link inside headings

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: var(--#{$prefix}heading-font-weight);
  > a {
    color: var(--#{$prefix}heading-link-color);
    text-decoration: none;
    &:hover {
      color: var(--#{$prefix}heading-link-hover-color);
    }
  }
}


// Blockquote

.blockquote {
  position: relative;
  color: var(--#{$prefix}blockquote-color);
  @include font-size(var(--#{$prefix}blockquote-font-size));
  padding-left: calc(var(--#{$prefix}blockquote-mark-size) + 1.75rem);
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    margin: -.1875rem -.1875rem $spacer -.1875rem;
    color: var(--#{$prefix}blockquote-mark-color);
    font: {
      family: $icons-font-family;
      size: var(--#{$prefix}blockquote-mark-size);
    }
    line-height: 1;
    content: '\ea3f';
  }
}

.blockquote-footer {
  display: flex;
  margin: 0;
  padding: {
    top: $blockquote-margin-y * .5;
    left: calc(var(--#{$prefix}blockquote-mark-size) + 1.75rem);
  }
  color: var(--#{$prefix}blockquote-footer-color);
  @include font-size(var(--#{$prefix}blockquote-footer-font-size));
  font-weight: $blockquote-footer-font-weight;
  &::before {
    display: block;
    width: 2rem;
    height: .0625rem;
    margin: {
      top: $spacer * .75;
      right: $spacer;
    }
    background-color: var(--#{$prefix}blockquote-footer-color);
    content: '';
  }
}

.text-center {
  .blockquote {
    padding-left: 0;
    &::before {
      position: static;
      display: block;
    }
  }
  .blockquote-footer {
    justify-content: center;
    padding-left: 0;
    &::before { display: none; }
  }
}

.text-end {
  .blockquote {
    padding: {
      left: 0;
      right: calc(var(--#{$prefix}blockquote-mark-size) + 1.75rem);
    }
    &::before {
      right: 0;
      left: auto;
    }
  }
  .blockquote-footer {
    justify-content: end;
    padding: {
      left: 0;
      right: calc(var(--#{$prefix}blockquote-mark-size) + 1.75rem);
    }
  }
}

@include media-breakpoint-down(sm) {
  .blockquote {
    padding-left: calc((var(--#{$prefix}blockquote-mark-size) * .75) + 1rem);
    &::before {
      font-size: calc(var(--#{$prefix}blockquote-mark-size) * .75);
      margin-bottom: $spacer * .75;
    }
  }
  .blockquote-footer {
    padding-left: calc((var(--#{$prefix}blockquote-mark-size) * .75) + 1rem);
    &::before {
      width: 1.25rem;
      margin-right: $spacer * .75;
    }
  }
  .text-end {
    .blockquote,
    .blockquote-footer {
      padding-right: calc((var(--#{$prefix}blockquote-mark-size) * .75) + 1rem);
    }
  }
}


// Description lists

dt {
  color: var(--#{$prefix}heading-color);
  font-weight: var(--#{$prefix}heading-font-weight);
}


// Dark mode for general type colors

@if $enable-dark-mode {
  body .dark-mode {
    p, ul, ol, span, dd {
      color: var(--#{$prefix}body-color);
    }
  }
}


// Del muted

del.text-muted {
  color: var(--#{$prefix}gray-500) !important;
}
