//
// Date / Time Picker
// based on https://github.com/flatpickr/flatpickr
// --------------------------------------------------


.date-picker {
  &[readonly] {
    background-color: $input-bg;
  }
  &:focus {
    border-color: $input-focus-border-color;
  }
}
.flatpickr-calendar {
  width: 325px;
  padding: 0 .5rem;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  box-shadow: $dropdown-box-shadow;
  &::before, &::after { display: none; }
}
.flatpickr-innerContainer {
  padding-bottom: 1.125rem;
}
.flatpickr-months {
  padding: .75rem 0;
  svg { vertical-align: top; }
  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: .75rem;
    svg { fill: var(--#{$prefix}gray-800); }
    &:hover svg { fill: var(--#{$prefix}primary); }
  }
}
span.flatpickr-weekday,
.flatpickr-time .flatpickr-time-separator {
  color: var(--#{$prefix}gray-600);
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  padding: .25rem;
  appearance: none;
}
.numInputWrapper,
.numInput,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: var(--#{$prefix}gray-900) !important;
}
.numInputWrapper:hover,
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: var(--#{$prefix}gray-200);
}
.numInput:hover {
  background-color: transparent !important;
}
.flatpickr-day {
  height: 38px;
  border-radius: 0;
  @include border-radius(var(--#{$prefix}border-radius));
  color: var(--#{$prefix}gray-900);
  line-height: 37px;
  &:hover:not(.flatpickr-disabled):not(.today):not(.selected) {
    border-color: var(--#{$prefix}gray-200);
    background-color: var(--#{$prefix}gray-200);
  }
  &.today {
    border-color: rgba(var(--#{$prefix}primary-rgb), .5) !important;
    color: var(--#{$prefix}primary) !important;
    &.selected { color: $white !important; }
    &:hover { background-color: transparent; }
  }
  &.selected {
    border-color: var(--#{$prefix}primary) !important;
    background-color: var(--#{$prefix}primary) !important;
  }
  &.flatpickr-disabled {
    color: var(--#{$prefix}gray-500) !important;
  }
  &.prevMonthDay,
  &.nextMonthDay {
    color: var(--#{$prefix}gray-600);
  }
  &.inRange {
    border-color: var(--#{$prefix}gray-200) !important;
    background-color: var(--#{$prefix}gray-200) !important;
    box-shadow: -5px 0 0 var(--#{$prefix}gray-200), 5px 0 0 var(--#{$prefix}gray-200);
  }
  &.startRange,
  &.endRange,
  &.endRange.seleced,
  &.endRange:hover {
    border-color: var(--#{$prefix}primary) !important;
    background-color: var(--#{$prefix}primary) !important;
  }
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0;
  @include border-radius(0 $border-radius $border-radius 0);
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 0;
  @include border-radius($border-radius 0 0 $border-radius);
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 $primary;
}
.flatpickr-time {
  margin-bottom: .5rem;
  .flatpickr-am-pm {
    color: var(--#{$prefix}gray-800);
  }
  input:hover,
  .flatpickr-am-pm:hover,
  input:focus,
  .flatpickr-am-pm:focus {
    background-color: var(--#{$prefix}gray-200);
  }
  input.flatpickr-hour {
    font-weight: $font-weight-semibold;
  }
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-color: var(--#{$prefix}border-color);
}
