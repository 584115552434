//
// List group
// --------------------------------------------------


// Interactive list items

.list-group-item-action {
  transition: $list-group-action-transition;
  color: var(--#{$prefix}list-group-action-color);
  font-weight: $list-group-action-font-weight;
  &:active {
    transition: none;
  }
}


// Flush list group

.list-group-flush .list-group-item {
  padding: {
    right: 0;
    left: 0;
  }
}
